@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,700i");

:root {
  --border-color: #eeee;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  /* font-size: 62.5%; */
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

#root {
  height: 100vh;
}

body {
  background: #fff;
  font-family: "Roboto", sans-serif;
  color: #242424;
  overflow: hidden;
}

.App {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button:focus {
  outline: none;
}

img {
  max-width: 100%;
}

.wrapper {
  width: 136rem;
  max-width: 95%;
  margin: 0 auto;
}

h1 {
  font-size: 3.6rem;
  font-weight: 700;
}

.main-tittle {
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
}

/* .shadow-box {
  box-shadow: 0px 4px 30px 0px rgba(191, 191, 191, 0.2);
} */

h2.sub-heading {
  color: #8e8e8e;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

p.press-review {
  font-weight: 500;
  text-align: right;
}

.no-feedback {
  color: #c0392b;
  margin-left: 1.5rem;
}

.feedback {
  color: #7c7c7c;
  font-weight: 400 !important;
  margin-left: 1.5rem;
}
.confirmed {
  color: #196f3d;
  font-weight: 400 !important;
  margin-left: 1.5rem;
}
.def-btn {
  padding: 1.2rem 3rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.red-button {
  background: #016f8a;
  color: #fff;
  margin-left: 2.3rem;
}

.empty-button {
  background: none;
  border: 2px solid #eeeeee;
}

/* @media (max-width: 1440px) {
  html {
    font-size: 55.5%;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 51.5%;
  }
} */
