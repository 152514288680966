.edit__container {
  padding: 50px;
  width: 100%;
}
.edit__inputs {
  margin-bottom: 20px;
  /* max-width: 500px; */
}
.edit__input-box {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
.edit__input-cell label {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 10px;
}

.edit__input-cell {
  width: 180px;
}
.edit__input-country-row {
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  height: 100px;
}

.edit__input-box label {
  font-weight: 600;
  margin-bottom: 10px;
}
.edit__buttons {
  display: flex;
  justify-content: space-between;
}

.edit__checkboxes-container {
  margin-bottom: 40px;
  max-width: 500px;
}
.edit__checkboxes-container_large {
  margin-bottom: 40px;
  max-width: 900px;
}
.edit__checkboxes-text {
  font-weight: bold;
  margin-bottom: 5px;
}
.edit__checkboxes-box {
  max-height: 200px;
  overflow-y: auto;
}

.country_select {
  min-width: 100px;
}

@media screen and (max-width: 780px) {
  .edit__buttons {
    flex-direction: column;
    align-items: flex-start;
  }
}

.edit__buttons {
  display: flex;
  justify-content: space-between;
}
