.dashboard {
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  padding-bottom: 2.4rem;
  height: calc(100vh - 7.2rem);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding-top: 2.4rem;
}

.dashboard img {
  cursor: pointer;
}

.dashboard-top {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
}

.dashboard-legend {
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
  display: flex;
  align-items: center;
}

.dashboard-legend img {
  width: 2.4rem;
  height: 2.4rem;
}

.dashboard-legend p {
  font-size: 1.4rem;
  color: #718096;
}

.dashboard-legend span {
  margin-left: 1.6rem;
}

.dashboard-legend span:last-child {
  margin-top: -5px;
}

.dash-current-projects {
  flex: 1 0 30%;
}

.dashboard-key-dates {
  flex: 1 0 70%;
  padding-left: 4rem;
}

.dash-currnet-projects-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}

h1.dash-tittle {
  font-size: 2rem;
  font-weight: 700;
  position: relative;
  padding-left: 18px;
}

h1.dash-tittle:before {
  content: "";
  background: #066e89;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
}

.dash-currnet-projects-top p {
  font-size: 1.6rem;
  color: #016f8a;
}

.dash-currnet-project-list {
  display: flex;
  flex-direction: column;
  height: 37vh;
  overflow-y: auto;
}

.dash-current-project-item {
  display: flex;
  border: 1px solid var(--border-color);
  align-items: center;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
  cursor: pointer;
  position: relative;
}

.current-item-arrow {
  position: absolute;
  right: 0rem;
  opacity: 0;
  right: 1.6rem;
  transition: all 0.1s ease-in-out;
}

.dash-current-project-item:hover {
  background: #f7fafc;
}

.dash-current-project-item:hover .current-item-arrow {
  opacity: 1;
  right: 2.6rem;
}

.img-cover {
  width: 6.4rem;
  height: 6.4rem;
}

.current-item-tittle {
  margin-left: 4rem;
}

.current-item-tittle h1 {
  font-size: 1.8rem;
  font-weight: 400;
}

.current-item-tittle p {
  font-size: 1.4rem;
  color: #718096;
  margin-top: 0.2rem;
}

.key-dates-table {
  margin-top: 1.6rem;
}

.key-dates-header {
  display: grid;
  grid-template-columns: 0.7fr 1fr 0.7fr 1fr 1fr 0.3fr 0.7fr;
  background: #f7fafc;
  height: 4.8rem;
  align-items: center;
  border: 1px solid #cbd5e0;
  border-radius: 3px 3px 0px 0px;
  padding: 0 1.6rem;
}

.key-dates-header p {
  font-size: 1.4rem;
  color: #2a4365;
}

.body-item {
  height: 6.4rem;
  display: grid;
  grid-template-columns: 0.7fr 1fr 0.7fr 1fr 1fr 0.3fr 0.7fr;
  grid-gap: 0.8rem;
  align-items: center;
  border: 1px solid #cbd5e0;
  padding: 0 1.6rem;
  margin-top: -1px;
}

.table-artist h1 {
  font-size: 1.6rem;
  white-space: nowrap;
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-artist p {
  font-size: 1.4rem;
  white-space: nowrap;
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.body-item p {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

span.green-dot {
  display: block;
  width: 12px;
  height: 12px;
  background: #93e088;
  border-radius: 100%;
  margin-right: 8px;
  position: relative;
}

span.green-dot:after {
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: rgba(33, 166, 70, 0.18);
  z-index: -1;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

span.red-dot {
  display: block;
  width: 12px;
  height: 12px;
  background: #ba1e45;
  border-radius: 100%;
  margin-right: 8px;
  position: relative;
}

span.red-dot:after {
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fae8e6;
  z-index: -1;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

.red_text {
  color: #ba1e45;
}
.green_text {
  color: #93e088;
}

.dash-note {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  position: relative;
}
.dash-note.np {
  margin-left: 0;
  border-right: none;
}
.dash-note-simple {
  position: relative;
}
.dash-note img {
  width: 2.4rem;
  height: 2.4rem;
}

.dash-note:hover .dash-tooltip {
  display: block;
  z-index: 20;
}
.dash-note-simple:hover .dash-tooltip {
  display: block;
  z-index: 20;
}

.dash-tooltip {
  display: none;
  position: absolute;

  left: 80%;
  z-index: 1;
  background: #2d3748;
  border: 1px solid #4a5568;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0.8rem 1.6rem;
}

.dash-tooltip.lf {
  left: auto;
  right: 100%;
  color: #f7fafc;
}
.dash-tooltip.fix {
  left: auto;
  margin-left: 30px;
  position: fixed;
  color: #f7fafc;
}
.dash-tooltip p {
  white-space: pre;
  color: #f7fafc;
}
.dash-tooltip.lf p {
  white-space: pre;
  color: #f7fafc;
}
.dash-tooltip.fix p {
  white-space: pre;
  color: #f7fafc;
}
.same-date {
  justify-self: center;
}

.same-date img {
  width: 3.2rem;
}

.key-dates-body {
  height: 32vh;
  overflow-y: auto;
}

.dashboard-bottom {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
}

.dash-bottom-left {
  flex: 1 0 49%;
  margin-right: 1%;
}

.dash-bottom-right {
  flex: 1 0 49%;
  margin-left: 1%;
}

.pending-assets-header {
  background: #f7fafc;
  height: 4.8rem;
  align-items: center;
  border: 1px solid #cbd5e0;
  display: grid;
  grid-template-columns: 30rem 0.7fr 0.76fr 0.7fr 0.15fr;
  grid-gap: 0.8rem;
  place-items: center;
  padding: 0 1.6rem;
}

.pending-assets-table {
  margin-top: 1.6rem;
}

.pending-assets-header p {
  font-size: 1.4rem;
  color: #2a4365;
}

.pending-assets-item p {
  font-size: 1.4rem;
  color: #212b36;
}

.pending-assets-item {
  display: grid;
  grid-template-columns: 30rem 0.7fr 0.76fr 0.7fr 0.15fr;
  grid-gap: 0.8rem;
  align-items: center;
  border: 1px solid #e2e8f0;
  margin-top: -1px;
  height: 4.8rem;
  place-items: center;
  padding: 0 1.4rem;
}
.pending-assets-item > div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.pending-assets-header > div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.p-a-tittle p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.p-a-tittle {
  place-self: stretch;
  align-self: center;
  padding-left: 0.9rem;
}

.pending-assets-body {
  height: 30vh;
  overflow-y: auto;
}

.base-bubble {
  background: #ffffff;
  border-radius: 30px;
  border: 1px solid #b1b1b1;
  padding: 0 1.2rem;
  color: #555555;
  font-weight: 400;
}

.red-bubble {
  background: #fff5f5;
  border-radius: 30px;
  border: 1px solid #fc8181;
  padding: 0 1.2rem;
  color: #e53e3e !important;
  font-weight: 400;
}

.yellow-bubble {
  background: #fffaf0;
  border-radius: 30px;
  border: 1px solid #ed8936;
  padding: 0 1.2rem;
  color: #dd6b20 !important;
  font-weight: 400;
}

.orange-bubble {
  background: #feebc8;
  border-radius: 30px;
  border: 1px solid #f6ad55;
  padding: 0 1.2rem;
  color: #dd6b20 !important;
  font-weight: 400;
}

.green-bubble {
  background: #f0fff4;
  border-radius: 30px;
  border: 1px solid #68d391;
  padding: 0 1.2rem;
  color: #38a169 !important;
  font-weight: 400;
}

.purple-bubble {
  background: #faf5ff;
  border-radius: 30px;
  border: 1px solid #7f9cf5;
  padding: 0 1.2rem;
  color: #5a67d8 !important;
  font-weight: 400;
}

.media-high-table {
  margin-top: 1.6rem;
}

.media-high-header {
  background: #f7fafc;
  height: 4.8rem;
  align-items: center;
  border: 1px solid #cbd5e0;
  display: grid;
  grid-template-columns: 30rem 0.5fr 1fr 0.7fr 0.3fr 0.3fr;
  grid-gap: 0.8rem;
  padding: 0 1.6rem;
}

.media-high-header p {
  font-size: 1.4rem;
  color: #2a4365;
}

.media-high-item {
  display: grid;
  grid-template-columns: 30rem 0.5fr 1fr 0.7fr 0.3fr 0.3fr;
  grid-gap: 0.8rem;
  align-items: center;
  border: 1px solid #e2e8f0;
  height: 4.8rem;
  padding: 0 1.6rem;
  margin-top: -1px;
  height: 6.4rem;
}

.media-high-item p {
  font-size: 1.4rem;
  color: #212b36;
}

.media-high-item h1 {
  font-size: 1.6rem;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.media-high-body {
  height: 30vh;
  overflow-y: auto;
}

@media (max-width: 890px) {
  .tabs-panel {
    width: 100%;
  }
  .dash-currnet-project-list {
    height: inherit;
  }
  .dashboard {
    padding-right: 1.4rem;
    padding-left: 1.4rem;
  }

  .key-dates-body {
    height: inherit;
  }
  .pending-assets-body {
    height: inherit;
  }

  .media-high-body {
    height: inherit;
  }
  .dashboard-top {
    width: 100%;
  }
  .dash-current-projects {
    flex: 1 0 100%;
  }

  .dashboard-legend {
    display: none;
  }

  .dashboard-key-dates {
    flex: 1 0 100%;
    padding-left: 0;
    margin-top: 3rem;
  }

  .dashboard-bottom {
    margin-top: 3rem;
  }

  .key-dates-header {
    grid-template-columns: 120px 120px 120px 120px 120px 70px 120px;
  }

  .body-item {
    grid-template-columns: 120px 120px 120px 120px 120px 70px 120px;
  }

  .pending-assets-header {
    grid-template-columns: 30rem 150px 150px 150px 70px;
  }

  .pending-assets-item {
    grid-template-columns: 30rem 150px 150px 150px 70px;
  }
  .dash-bottom-left {
    flex: 1 0 100%;
    width: 100%;
    margin-right: 0;
  }

  .dash-bottom-right {
    margin-top: 3rem;
    flex: 1 0 100%;
    width: 100%;
    margin-left: 0;
  }
}

.header {
  background-color: #3f51b5;
  color: #fff;
}

.table-wrapper {
  padding: 20px;
}

.table {
  border: 1px solid #eee;
}
.table__header {
  padding: 15px;
}

.table__item {
  border-top: 1px solid #eee;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
