.header__logo {
  font-size: 32px;
}
.footer-content {
  padding: 0 80px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #eee;
}

.footer-text {
  text-align: center;
}

.footer-content a {
  border-bottom: 1px solid transparent;
  transition: all 0.15s ease-in-out;
}

.footer-content a:hover {
  border-color: #eee;
}

.footer-support-link {
  display: flex;
  align-items: center;
}

.header__hamburger-btn {
  display: none;
  cursor: pointer;
}

.Layout-sidebar-17 .open {
  transform: translateX(0);
}

@media screen and (max-width: 780px) {
  .Layout-sidebar-17 {
    position: absolute;
    transform: translateX(-100%);
    transition: 0.2s all ease-in-out;
    z-index: 101;
  }
  .header__hamburger-btn {
    display: block;
  }
}

@media screen and (max-width: 580px) {
  .Layout-sidebar-17 {
    width: 100% !important;
  }

  .header__logo {
    font-size: 20px;
  }

  .footer-content {
    padding: 0 20px;
  }
}
