.table-edit__container {
  height: 90%;
  padding: 30px;
}

.table-edit__header {
  margin-bottom: 20px;
  display: flex;
}

.loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
